import React, { createContext, useContext, useState, useEffect } from "react";
import { getUserType, UserDataFromToken } from "../Service";
import { UserType } from "./DropdownOption";

// Create a context for the user's role
const RoleContext = createContext();

// Export a hook to use the role context
export const useRole = () => useContext(RoleContext);

// RoleProvider component to provide role state to the app
export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(null);

  // Fetch role from UserDataFromToken, ensure UserDataFromToken is available
  const getRoleForAuth = () => {
    const storedRole = UserType[getUserType() - 1]?.type;

    // Log the fetched role for debugging
    // console.log("Fetched role:", storedRole);

    if (storedRole) {
      setRole(storedRole);
    }
  };

  // Initially set the role
  // useEffect(() => {
  //   getRoleForAuth(); // Call the function once when component mounts
  // }, []);

  return (
    <RoleContext.Provider value={{ role, setRole, getRoleForAuth }}>
      {children}
    </RoleContext.Provider>
  );
};
