import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import FileManager from "./components/Templates/upload.js";
import VendorDetail from "./components/MainPage/Vendors_Page/VendorDetail.js";

import Vendor_Calendar from "./components/MainPage/Vendor_calendar/Vendor_Calendar.js";

// Role Context and ProtectedRoute
import ProtectedRoute from "./components/ProtectedRoute.js";
import { RoleProvider, useRole } from "./components/RoleContext.js";
import { CircularProgress, Stack } from "@mui/material";

// Component imports
const FileManager = React.lazy(() =>
  import("./components/Templates/upload.js")
);
const VendorDetailTabs = React.lazy(() =>
  import("./components/Templates/VendorDetailTabs.js")
);
const Review_Rating = React.lazy(() =>
  import("./components/MainPage/Review_Rating/Review_Rating.js")
);

const User_details = React.lazy(() =>
  import("./components/Templates/UserDetails.js")
);
const SIgnUp = React.lazy(() => import("./components/SignUp/SIgnUp.js"));
const Password_Recovery = React.lazy(() =>
  import("./components/Password_Recovery/Password_Recovery.js")
);
const Two_Factor_Auth = React.lazy(() =>
  import("./components/Two_Factor_Auth/Two_Factor_Auth.js")
);
const New_Password = React.lazy(() =>
  import("./components/New_Password/New_Password.js")
);
const Login = React.lazy(() => import("./components/Login/Login.js"));
const Forgot_Password = React.lazy(() =>
  import("./components/Forgot_Password/Forgot_Password.js")
);
const Dashboard = React.lazy(() =>
  import("./components/MainPage/Dashboard/Dashboard.js")
);
const MainPage = React.lazy(() => import("./components/MainPage/MainPage.js"));
const Vendor = React.lazy(() =>
  import("./components/MainPage/Vendors_Page/vendor.js")
);
const Users = React.lazy(() => import("./components/MainPage/Users/Users.js"));
const Ads = React.lazy(() => import("./components/MainPage/Ads/ads.js"));
const Report = React.lazy(() =>
  import("./components/MainPage/Report_Page/report.js")
);
const Helpline = React.lazy(() =>
  import("./components/MainPage/Helpline/helpline.js")
);

const Profile = React.lazy(() => import("./components/Profile/profile.js"));
const Unauthorized = React.lazy(() =>
  import("./components/Templates/Unauthorized.js")
); // Add unauthorized page

const VendorStepper = React.lazy(() =>
  import("./components/MainPage/Vendor_Stepper/VendorStepper.js")
);
const Revenue = React.lazy(() =>
  import("./components/MainPage/revenue/Revenue.js")
);
const Booking = React.lazy(() =>
  import("./components/MainPage/booking/Booking.js")
);

const Notification = React.lazy(() =>
  import("./components/Notification/Notification.js")
);

function App() {
  return (
    <RoleProvider>
      <Router>
        <ToastContainer />
        <Suspense
          fallback={
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, 50%)",
                zIndex: "3000",
              }}
            >
              <Stack spacing={2} direction="row" alignItems="center">
                <CircularProgress size="50px" />
              </Stack>
            </div>
          }
        >
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<Forgot_Password />} />
            <Route
              path="/password-recovery/:encryptedData"
              element={<Password_Recovery />}
            />
            <Route
              path="/two-factor-auth/:encryptedData"
              element={<Two_Factor_Auth />}
            />
            <Route path="/reset-password/:token" element={<New_Password />} />
            <Route path="/sign-up" element={<SIgnUp />} />
            <Route path="/unauthorized" element={<Unauthorized />} />{" "}
            {/* <Route path="/reset-password" element={<New_Password />} /> */}
            {/* Unauthorized route */}
            {/* Protected Main Layout (MainPage) */}
            <Route
              path="/main"
              element={
                <ProtectedRoute
                  allowedRoles={[
                    "Super Admin",
                    "Admin",
                    "Sales Representative",
                    "Vendor",
                    "Back Office Operator",
                  ]}
                >
                  <MainPage />
                </ProtectedRoute>
              }
            >
              {/* Protected nested routes under MainPage */}
              <Route
                path="dashboard"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Sales Representative",
                      "Vendor",
                      "Back Office Operator",
                    ]}
                  >
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vendor"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Sales Representative",
                      "Back Office Operator",
                    ]}
                  >
                    <Vendor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vendor/vendor_detail"
                element={<VendorDetailTabs />}
              />
              <Route
                path="users"
                element={
                  <ProtectedRoute allowedRoles={["Super Admin", "Admin"]}>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="ads"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Sales Representative",
                      "Back Office Operator",
                    ]}
                  >
                    <Ads />
                  </ProtectedRoute>
                }
              />
              <Route
                path="rating"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Vendor",
                      "Sales Representative",
                      "Back Office Operator",
                    ]}
                  >
                    <Review_Rating />
                  </ProtectedRoute>
                }
              />
              <Route
                path="report"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Vendor",
                      "Sales Representative",
                      "Back Office Operator",
                    ]}
                  >
                    <Report />
                  </ProtectedRoute>
                }
              />
              <Route
                path="helpline"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Vendor",
                      "Sales Representative",
                      "Back Office Operator",
                    ]}
                  >
                    <Helpline />
                  </ProtectedRoute>
                }
              />
              <Route
                path="profile"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Vendor",
                      "Sales Representative",
                      "Back Office Operator",
                    ]}
                  >
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="platform-setting"
                element={
                  <ProtectedRoute allowedRoles={["Super Admin", "Admin"]}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="vendor-stepper"
                element={
                  <ProtectedRoute
                    allowedRoles={[
                      "Super Admin",
                      "Admin",
                      "Sales Representative",
                      "Vendor",
                    ]}
                  >
                    <VendorStepper />
                  </ProtectedRoute>
                }
              />

              <Route path="revenue" element={<Revenue />} />
              <Route path="booking" element={<Booking />} />
              <Route path="notification" element={<Notification />} />
            </Route>{" "}
            {/*main closing routing */}
            <Route path="vendor-calender" element={<Vendor_Calendar />} />
            {/* Catch-all route */}
            <Route path="*" element={"Invalid Route"} />
          </Routes>

          {/* Add RouteChangeHandler to trigger getRoleForAuth on route change */}
          <RouteChangeHandler />
        </Suspense>
      </Router>
    </RoleProvider>
  );
}

// RouteChangeHandler component
const RouteChangeHandler = () => {
  const location = useLocation();
  const { getRoleForAuth } = useRole();

  useEffect(() => {
    // Call getRoleForAuth whenever the location (route) changes
    getRoleForAuth();
  }, [location, getRoleForAuth]);

  return null;
};

export default App;
