import PersonalInformation from "./Templates/VendorDetailsPages/PersonalInformation";
import CompanyDetails from "./Templates/VendorDetailsPages/CompanyDetails";
import SocialDetail from "./Templates/VendorDetailsPages/SocialDetail";
import Amenities from "./Templates/VendorDetailsPages/Amenities";
import WorkingDetail from "./Templates/VendorDetailsPages/WorkingDetail";
import Service from "./Templates/VendorDetailsPages/ServiceDetail";
import VenueRulepage from "./Templates/VendorDetailsPages/VenueRulepage";
import BankingDetail from "./Templates/VendorDetailsPages/BankingDetail";
import AdsBox from "./Templates/AdsBox";

export const UserType = [
  { type: "Super Admin", value: 1 },
  { type: "Admin", value: 2 },
  { type: "Sales Representative", value: 3 },
  { type: "Vendor", value: 4 },
  { type: "Back Office Operator", value: 5 },
];

export const NewBannerStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Expired", value: "expired" },
];

export const AdsStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Expired", value: "expired" },
];

export const TargetAudeience = [
  { label: "All", value: "all" },
  { label: "New User", value: "new_users" },
  { label: "Returning User", value: "returning_users" },
  { label: "Premium User", value: "premium_users" },
];

export const VendorNameList = [
  { id: 1, label: "Label 1" },
  { id: 2, label: "Label 2" },
  { id: 3, label: "Label 3" },
  { id: 4, label: "Label 4" },
  { id: 5, label: "Label 5" },
  // Continue adding objects as needed
];

export const AdsType = [{ label: "Banner Ads" }, { label: "Vendor Offer" }];

export const vendorRules = [
  {
    RuleName: "Allows Returns",
    Description:
      "Vendors who opt for this rule agree to accept product returns from customers under specified conditions.",
    RuleValue: false, // Default value: false (unchecked)
  },
  {
    RuleName: "Free Shipping",
    Description:
      "This rule enables vendors to provide free shipping for their products.",
    RuleValue: false,
  },
  {
    RuleName: "Express Delivery",
    Description:
      "Vendors can offer express delivery options for faster shipment.",
    RuleValue: false,
  },
  {
    RuleName: "Cash on Delivery",
    Description:
      "Allows customers to pay for products upon delivery instead of pre-paying.",
    RuleValue: false,
  },
];

export const tabs = [
  {
    id: "personal",
    label: "Company Details",
    component: ({ data }) => <PersonalInformation data={data.vendor} />,
  },
  // {
  //   id: "companyDetails",
  //   label: "Company Details",
  //   component: ({ data }) => <CompanyDetails data={data.vendor} />,
  // },
  {
    id: "socialDetails",
    label: "Social Details",
    component: ({ data }) => {
      const dataSend = { ...data.social, images: data.images };
      return <SocialDetail data={dataSend} />;
    },
  },
  {
    id: "amenities",
    label: "Amenities",
    component: ({ data }) => <Amenities data={data.amenities} />,
  },
  {
    id: "workingHourDetail",
    label: "Working Hour Detail",
    component: ({ data }) => <WorkingDetail data={data.working_hours} />,
  },
  {
    id: "serviceDetail",
    label: "Service Detail",
    component: ({ data }) => <Service data={data.services} />,
  },
  {
    id: "venueRules",
    label: "Venue Rules",
    component: ({ data }) => <VenueRulepage data={data.vendorRules} />,
  },
  {
    id: "bankingDetail",
    label: "Banking Detail",
    component: ({ data }) => <BankingDetail data={data.banking} />,
  },
];

export const VendorStatus = [
  // { label: "All", value: "" },
  { label: "Draft", value: "Draft" },
  { label: "Submitted For Approval", value: "Submitted For Approval" },
  { label: "Rejected", value: "Rejected" },
  { label: "Active", value: "Active" },
  { label: "Disabled", value: "Disabled" },
  { label: "Temporarily Closed", value: "Temporarily Closed" },
];

export const TicketStatus = [
  { label: "Active", value: "Active" },
  { label: "Pending", value: "Pending" },
  { label: "Closed", value: "Closed" },
];

export const TicketCategories = [
  { label: "Account", value: "Account" },
  { label: "Login", value: "Login" },
  { label: "Password", value: "Password" },
  { label: "Payment", value: "Payment" },
  { label: "Profile", value: "Profile" },
  { label: "Refund", value: "Refund" },
];

export const ReviewRatingOptions = [
  { label: "All Stars", value: "" },
  { label: "4 stars", value: 4 },
  { label: "3 stars", value: 3 },
  { label: "2 stars", value: 2 },
  { label: "1 stars", value: 1 },
];

export const tempData = [
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Banner",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },

  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
  {
    name: "Jacob",
    city: "Mumbai",
    type: "Offer",
    priority: "Upto 150 off",
    end_date: "20 May 2024",
    description:
      "Deserunt sit irure sit duis occaecat et velit. Magna nostrud consequat cupidatat nulla consequat veniam aliqua officia nisi deserunt labore. Do et non esse sit ea.",
    amenities: ["Toilet", "Additional Offerings", "Parking"],
  },
];

export const service_area_data = [
  {
    title: "Sports",
    _id: "01",
    sub_category: [
      {
        title: "Cricket",
        _id: "01",
      },
      {
        title: "Football",
        _id: "02",
      },
      {
        title: "Badminton",
        _id: "03",
      },
      {
        title: "Box Cricket",
        _id: "04",
      },
      {
        title: "Turf",
        _id: "05",
      },
      {
        title: "Basket Ball",
        _id: "06",
      },
      {
        title: "Golf",
        _id: "07",
      },
      {
        title: "Smash",
        _id: "08",
      },
    ],
  },
  {
    title: "Coaching",
    _id: "02",
    sub_category: [
      {
        title: "Cricket",
        _id: "01",
      },
      {
        title: "Football",
        _id: "02",
      },
      {
        title: "Badminton",
        _id: "03",
      },
    ],
  },
  {
    title: "Academic classes",
    _id: "03",
    sub_category: [
      {
        title: "Abacus",
        _id: "01",
      },
      {
        title: "Vedic Maths",
        _id: "02",
      },
      {
        title: "Chess",
        _id: "03",
      },
      {
        title: "Phonexis Chess",
        _id: "04",
      },
      {
        title: "Dance Class",
        _id: "05",
      },
      {
        title: "Yoga And Zumba",
        _id: "06",
      },
    ],
  },
  {
    title: "Play zone",
    _id: "04",
    sub_category: [
      {
        title: "Paint Ball & Neon Color Play",
        _id: "01",
      },
    ],
  },
  {
    title: "Primary school & day care",
    _id: "05",
    sub_category: [
      {
        title: "All Nursery School",
        _id: "01",
      },
      {
        title: "Primary School And Daycare",
        _id: "02",
      },
    ],
  },
  {
    title: "Events",
    _id: "06",
    sub_category: [
      {
        title: "Drawing",
        _id: "01",
      },
      {
        title: "Paining Event",
        _id: "02",
      },
      {
        title: "Clay Event Hollownin",
        _id: "03",
      },
      {
        title: "Trekking And Overnight Camping",
        _id: "04",
      },
      {
        title: "Flea Market",
        _id: "05",
      },
    ],
  },
];

export const faq= [
  {
    Question: "How to change My Password?",
    Answer:
      "Password change instructions here..."
  },
  {
    Question: "How to change or edit Services?",
    Answer:
      "Service editing instructions here..."
  },
  {
    Question: "How a vendor can Register?",
    Answer:
      "Vendor registration instructions here..."
  },
  {
    Question: "How to calculate Commission?",
    Answer:
      "Lorem ipsum dolor sit amet consectetur"
  },
];

export const ReportStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  // { label: "Expired", value: "expired" },
];

export const ReportTypeList = [
  { label: "User Reports", value: "User Reports" },
  { label: "Vendor List Report", value: "Vendor List Report" },
  { label: "Bookings Report", value: "Bookings Report" },
  { label: "Review and Ratings Report", value: "Review and Ratings Report" },
  { label: "Cancellation Report", value: "Cancellation Report" },
  { label: "Revenue Report", value: "Revenue Report" },
  { label: "Support Tickets Report", value: "Support Tickets Report" },
]

export const reportOptions = {
  "User Reports": [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ],
  "Vendor List Report": [
    { value: "approved", label: "Approved" },
    { value: "pending", label: "Pending" },
  ],
  "Bookings Report": [
    { value: "completed", label: "Completed" },
    { value: "canceled", label: "Canceled" },
    { value: "in-progress", label: "In Progress" },
  ],
  "Review and Ratings Report": [
    { value: "excellent", label: "Excellent" },
    { value: "good", label: "Good" },
    { value: "poor", label: "Poor" },
  ],
  "Cancellation Report": [
    { value: "user-initiated", label: "User-Initiated" },
    { value: "vendor-initiated", label: "Vendor-Initiated" },
  ],
  "Revenue Report": [
    { value: "high", label: "High" },
    { value: "medium", label: "Medium" },
    { value: "low", label: "Low" },
  ],
  "Support Tickets Report": [
    { value: "open", label: "Open" },
    { value: "closed", label: "Closed" },
    { value: "in-progress", label: "In Progress" },
  ],
};