import { Box, TextField } from "@mui/material";
import React, { useEffect } from "react";

const WorkingDetail = ({ data }) => {
  useEffect(() => {
    // Log or handle 'data' here
    console.log("data: ", data);
  }, [data]);

  // Group the data by Day, allowing multiple entries per day
  const groupByDay = (workingHours) => {
    const dayOrder = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    // Initialize an object with all days of the week and empty arrays for each
    const groupedHours = dayOrder.reduce((acc, day) => {
      acc[day] = []; // Initialize with an empty array to allow multiple entries
      return acc;
    }, {});

    // Fill in the actual data for days that exist in the workingHours array
    workingHours.forEach((hour) => {
      const day = hour.Day || "N/A"; // Use "N/A" for null days
      if (dayOrder.includes(day)) {
        groupedHours[day].push(hour); // Push each entry to allow multiple entries
      }
    });

    return groupedHours;
  };

  return (
    <div className="working-hours-container">
      <div>
        <h6>Hours</h6>
        <p style={{ color: "#94A3B8" }}>Open with main hours</p>
        <article className="mt-4">
          {Object.entries(groupByDay(data)).map(([day, hours], index) => (
            <section key={index} className="day-section">
              <div className="mb-3">
                <p>
                  <b>{day}</b>
                </p>
                {hours.length === 0 ? (
                  <p>No hours available</p>
                ) : (
                  hours.map((hour, idx) => (
                    <div
                      key={idx}
                      className="d-flex flex-direction-row align-items-center gap-5"
                    >
                      <div>
                        <div
                          className="d-flex flex-direction-row justify-content-start align-items-start gap-2"
                          style={{ width: "6em" }}
                        >
                          <input
                            type="checkbox"
                            id={`closed${index}-${idx}`}
                            checked={hour.Is_Closed === 1}
                            className="form-check-input"
                            readOnly
                          />
                          <label
                            htmlFor={`closed${index}-${idx}`}
                            style={{ color: "#94A3B8" }}
                          >
                            Closed
                          </label>
                        </div>
                      </div>
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            m: 1,
                            width: "12ch",
                          },
                          "& .MuiInputLabel-root": { color: "#94A3B8" },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#94A3B8" },
                            "&:hover fieldset": {
                              borderColor: "#F8FAFC",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#F8FAFC",
                            },
                          },
                          "& .MuiInputBase-input": {
                            color: "#94A3B8",
                            backgroundColor: "#F8FAFC",
                            height: "0.5em",
                            borderRadius: "10px",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        {hour.Is_Closed === 1 ? (
                          <TextField
                            id={`start-time${index}-${idx}`}
                            value="Closed"
                            InputProps={{ readOnly: true }}
                          />
                        ) : (
                          <div>
                            <TextField
                              id={`start-time${index}-${idx}`}
                              label="Start Time"
                              value={hour.Open_Time || "N/A"}
                              InputProps={{ readOnly: true }}
                            />
                            <TextField
                              id={`end-time${index}-${idx}`}
                              label="End Time"
                              value={hour.Close_Time || "N/A"}
                              InputProps={{ readOnly: true }}
                            />
                          </div>
                        )}
                      </Box>
                    </div>
                  ))
                )}
              </div>
            </section>
          ))}
        </article>
      </div>
    </div>
  );
};

export default WorkingDetail;
