import React from "react";
// import { FaDownload } from "react-icons/fa";
import '../Templates.css'

const PersonalInformation = ({ data }) => {
  const documents = [
    {
      name: "Company Registration Document",
      url: data.CompanyRegistrationDocumentLink,
    },
    { name: "PAN Document", url: data.PANDocumentLink },
    { name: "Udyam Aadhar Document", url: data.UdyamAadharDocumentLink },
    { name: "GST Document", url: data.GSTDocumentLink },
    { name: "Address Proof Document", url: data.AddressProofDocumentLink },
  ];

  // const CDNUrl = "https://rococo-cobbler-f7e684.netlify.app/drylab.pdf";

  // const downloadFile = (index) => {
  //   // const urlCreate = CDNUrl;
  //   // const link = document.createElement("a");
  //   // link.href = urlCreate;
  //   // link.target = "_blank";
  //   // link.download = urlCreate.split("/").pop(); // This will use the file name from the URL
  //   // document.body.appendChild(link);
  //   // link.click();
  //   // document.body.removeChild(link);
  // };

  return (
    <div className="user_detail_info">
      <span className="d-flex flex-direction-row justify-content-start  ">
        <div className="mb-2 w-50">
          <p className="mb-2">
            <b>Vendor Name</b>
          </p>
          <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
            <img
              src="/user_profile.png"
              alt="user_profile"
              style={{ width: "1.5em", height: "1.5em" }}
            />
            <p className="mb-0">{data?.VendorName || "N/A"}</p>
          </div>
        </div>
        <div className="w-50">
          {/* <p className="mb-2">
            <b>Company Type</b>
          </p>
          <p>{data?.CompanyRegistrationType || "N/A"}</p> */}
        </div>
      </span>
      {/* Email and Primary Number */}
      <span className="d-flex flex-direction-row justify-content-start usersMobile">
        <div className="w-50">
          <p className="mb-2">
            <b>Email</b>
          </p>
          <p>{data.Email || "N/A"}</p>
        </div>
        <div className="w-50">
          <p className="mb-2">
            <b>Primary Number</b>
          </p>
          <p>{data.PrimaryMobileNumber || "N/A"}</p>
        </div>
      </span>

      {/* Secondary and Whatsapp Numbers */}
      <span className="d-flex flex-direction-row justify-content-start usersMobile">
        <div className="w-50">
          <p className="mb-2">
            <b>Secondary Number</b>
          </p>
          <p>{data.SecondaryMobileNumber || "N/A"}</p>
        </div>
        <div className="w-50">
          <p className="mb-2">
            <b>Whatsapp Number</b>
          </p>
          <p>{data.WhatsappNumber || "N/A"}</p>
        </div>
      </span>

      {/* Address and State */}
      <div className="user_detail_info">
        {/*2*/}
        <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile">
          <div className="w-50">
            <p className="mb-2">
              <b>Company Registration</b>
            </p>
            <p>{data.CompanyRegistrationType || "N/A"}</p>
          </div>
          <div className="w-50">
            <p className="mb-2">
              <b>Pan Number</b>
            </p>
            <p>{data.PANNumber || "N/A"}</p>
          </div>
          {/* <div className="w-50">
            <p className="mb-2">
              <b>Company Registration Document</b>
            </p>
            <span className="d-flex flex-direction-row justify-content-between mb-3">
              <div className="d-flex flex-direction-row align-items-center gap-2">
                <img src="/file_icon.svg" alt="documentLogo" />
                <p style={{ fontSize: "0.8em", margin: 0, color: "#1E293B" }}>
                  {data.CompanyRegistrationDocumentLink || "N/A"}
                </p>
              </div>

              <img
                src="/download_icon.svg"
                alt="download_button"
                style={{ cursor: "pointer" }}
                // onClick={() => downloadFile(index)}
              />
            </span>
          </div> */}
        </span>
        {/* 3 */}
        {/* <span className="d-flex flex-direction-row justify-content-start mb-2">
          <div className="w-50">
            <p className="mb-2">
              <b>Pan Number Document</b>
            </p>
            <span className="d-flex flex-direction-row justify-content-between mb-3">
              <div className="d-flex flex-direction-row align-items-center gap-2">
                <img src="/file_icon.svg" alt="documentLogo" />
                <p style={{ fontSize: "0.8em", margin: 0, color: "#1E293B" }}>
                  {data.PANDocumentLink || "N/A"}
                </p>
              </div>

              <img
                src="/download_icon.svg"
                alt="download_button"
                style={{ cursor: "pointer" }}
                // onClick={() => downloadFile(index)}
              />
            </span>
          </div>
        </span> */}
        {/*4*/}
        <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile">
          <div className="w-50">
            <p className="mb-2">
              <b>Udyam Aadhar Number</b>
            </p>
            <p>{data.UdyamAadharNumber || "N/A"}</p>
          </div>
          <div className="w-50">
            <p className="mb-2">
              <b>GST Number</b>
            </p>
            <p>{data.GSTNumber || "N/A"}</p>
          </div>
          {/* <div className="w-50">
            <p className="mb-2">
              <b>Udyam Aadhar Number Document</b>
            </p>
            <span className="d-flex flex-direction-row justify-content-between mb-3">
              <div className="d-flex flex-direction-row align-items-center gap-2">
                <img src="/file_icon.svg" alt="documentLogo" />
                <p style={{ fontSize: "0.8em", margin: 0, color: "#1E293B" }}>
                  {data.UdyamAadharDocumentLink || "N/A"}
                </p>
              </div>

              <img
                src="/download_icon.svg"
                alt="download_button"
                style={{ cursor: "pointer" }}
                // onClick={() => downloadFile(index)}
              />
            </span>
          </div> */}
        </span>
        {/* 5 */}
        {/* <span className="d-flex flex-direction-row justify-content-start mb-2">
          <div className="w-50">
            <p className="mb-2">
              <b>GST Number Document</b>
            </p>
            <span className="d-flex flex-direction-row justify-content-between mb-3">
              <div className="d-flex flex-direction-row align-items-center gap-2">
                <img src="/file_icon.svg" alt="documentLogo" />
                <p style={{ fontSize: "0.8em", margin: 0, color: "#1E293B" }}>
                  {data.GSTDocumentLink || "N/A"}
                </p>
              </div>

              <img
                src="/download_icon.svg"
                alt="download_button"
                style={{ cursor: "pointer" }}
                // onClick={() => downloadFile(index)}
              />
            </span>
          </div>
        </span> */}
        {/* 6 */}

        <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile">
          <div className="w-50">
            <p className="mb-2">
              <b>Address</b>
            </p>
            <p>{data.Address || "N/A"}</p>
          </div>
          <div className="w-50">
            <p className="mb-2">
              <b>State</b>
            </p>
            <p>{data.State || "N/A"}</p>
          </div>
        </span>
        {/* 7 */}

        <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile">
          <div className="w-50">
            <p className="mb-2">
              <b>City</b>
            </p>
            <p>{data.City || "N/A"}</p>
          </div>
          <div className="w-50">
            <p className="mb-2">
              <b>Google Map Link</b>
            </p>
            <p>
              <a href="data.GoogleMapsLink" style={{ textDecoration: "none" }}>
                {data.GoogleMapsLink || "N/A"}
              </a>{" "}
            </p>
          </div>
        </span>
        {/* 8 */}

        <span className="d-flex flex-direction-row justify-content-start mb-2 usersMobile">
          <div className="w-50">
            <p className="mb-2">
              <b>Address Proof</b>
            </p>
            <p>{data.AddressProofType || "N/A"}</p>
          </div>

          <div className="w-50">
            <p className="mb-2">
              <b>Sales Representative</b>
            </p>
            <p>{data.SalesRepName || "N/A"}</p>
          </div>
          {/* <div className="w-50">
            <p className="mb-2">
              <b>Address Proof Document</b>
            </p>
            <span className="d-flex flex-direction-row justify-content-between mb-3">
              <div className="d-flex flex-direction-row align-items-center gap-2">
                <img src="/file_icon.svg" alt="documentLogo" />
                <p style={{ fontSize: "0.8em", margin: 0, color: "#1E293B" }}>
                  {data.AddressProofDocumentLink || "N/A"}
                </p>
              </div>

              <img
                src="/download_icon.svg"
                alt="download_button"
                style={{ cursor: "pointer" }}
                // onClick={() => downloadFile(index)}
              />
            </span>
          </div> */}
        </span>
      </div>

      {/* Documents Section */}
      <span>
        <h6
          style={{
            // color: "#64748B",
            fontWeight: "600",
            marginTop: "1em",
            marginBottom: "0.6em",
          }}
        >
          Documents
        </h6>
      </span>

      <div className="user_documents w-50">
        {documents.map((item, index) =>
          // Check if item.url is truthy before rendering
          item.url ? (
            <span
              className="d-flex flex-direction-row justify-content-between mb-3"
              key={index}
            >
              <div className="d-flex flex-direction-row align-items-center gap-2">
                <img src="/file_icon.svg" alt="documentLogo" />
                <p style={{ fontSize: "0.8em", margin: 0, color: "#1E293B" }}>
                  {item.name}
                </p>
              </div>

              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <img
                  src="/download_icon.svg"
                  alt="download_button"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </span>
          ) : null // Render nothing if item.url is falsy
        )}
      </div>

      {/* Vendor Status */}
      <div className="d-flex flex-direction-row justify-content-start">
        <div className="w-50">
          <p className="mb-2">
            <b>Status</b>
          </p>
          <div className="d-flex flex-direction-row justify-content-start align-items-start gap-2">
            <div>
              {(data.VendorStatus === "Active" ||
                data.VendorStatus === "Disabled") && (
                <p
                  style={{
                    backgroundColor:
                      data.VendorStatus === "Active" ? "#D1FAE5" : "gray",
                    padding: "0 1em",
                    borderRadius: "23px",
                    color: data.VendorStatus === "Active" ? "#059669" : "white",
                  }}
                >
                  {data.VendorStatus}
                </p>
              )}
              {(data.VendorStatus === "Rejected" ||
                data.VendorStatus == "Draft") && (
                <p
                  style={{
                    backgroundColor:
                      data.VendorStatus === "Rejected" ? "#FEE2E2" : "#FDBB05",
                    color:
                      data.VendorStatus === "Rejected" ? "#DC2626" : "white",
                    padding: "0 1em",
                    borderRadius: "23px",
                  }}
                >
                  {data.VendorStatus}
                </p>
              )}

              {data.VendorStatus == "Submitted For Approval" && (
                <p
                  style={{
                    backgroundColor: "#CCEAFF",
                    color: "#00365C",
                    padding: "0 1em",
                    borderRadius: "23px",
                  }}
                >
                  {data.VendorStatus}
                </p>
              )}

              {data.VendorStatus == "Temporarily Closed" && (
                <p
                  style={{
                    textAlign: "center",
                    backgroundColor: "#FF9B71",
                    color: "white",
                    padding: "0.6em 0.8em",
                    margin: 0,
                    fontSize: "0.8em",
                    borderRadius: "8px",
                  }}
                >
                  {data.VendorStatus}
                </p>
              )}
            </div>
          </div>
        </div>
        {(data.VendorStatus == "Disabled" ||
          data.VendorStatus == "Rejected") && (
          <div>
            <p className="mb-2">
              <b>Reason</b>
            </p>
            <p>{data.RejectionReason}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalInformation;
