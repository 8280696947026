import React, { useEffect } from "react";

const SocialDetail = ({ data }) => {
  // useEffect(() => {
  //   console.log("data: ", data);
  // }, []);

  // const downloadFile = (index) => {};
  return (
    <div className="user_detail_info">
      <span className="d-flex flex-direction-row justify-content-start mb-2">
        <div className="mb-3 w-50">
          <p className="mb-2">
            <b>Compay Description</b>
          </p>
          <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
            <p className="mb-0">{data?.VendorDescription || "N/A"}</p>
          </div>
        </div>
      </span>
      {/* 2 */}

      <div
      className="socialDetailsMobile"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <span
          className=" mb-2"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <div className="w-50">
            <p className="mb-2">
              <b>Website Details</b>
            </p>
            <p>{data?.Website || "N/A"}</p>
          </div>
          <div className="w-50">
            <p className="mb-2">
              <b>Google My Business ID</b>
            </p>
            <p>{data?.Google_My_Business_ID || "N/A"}</p>
          </div>
        </span>

        {/* 3 */}
        <span
          className=" mb-2 usersMobile"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <div className="w-50">
            <p className="mb-2">
              <b>Social Links</b>
            </p>
            {/* <p>{data.Email || "N/A"}</p> */}
          </div>

          <div className="w-50">
            <p className="mb-2">
              <b>Facebook</b>
            </p>
            <p>{data?.Facebook_link || "N/A"}</p>
          </div>

          <div className="w-50">
            <p className="mb-2">
              <b>Instagram</b>
            </p>
            <p>{data?.Instagram_link || "N/A"}</p>
          </div>

          <div className="w-50">
            <p className="mb-2">
              <b>X</b>
            </p>
            <p>{data?.X_link || "N/A"}</p>
          </div>
          <div>
            <p>
              <b></b>
            </p>
          </div>
        </span>
      </div>
      {/* 4 */}
      <div>
        <span className="d-flex flex-direction-row justify-content-start mb-2">
          <div className="mb-3 w-50">
            <p className="mb-2">
              <b>Service Area</b>
            </p>
            <div className="d-flex flex-direction-row justify-content-start align-items-center gap-2">
              <p className="mb-0">{data?.Service_Area || "N/A"}</p>
            </div>
          </div>
        </span>
      </div>

      {/* 5 */}
      {/* Images */}
      <span>
        <h6
          style={{
            // color: "#64748B",
            fontWeight: "600",
            marginTop: "1em",
            marginBottom: "0.6em",
          }}
        >
          Images
        </h6>
      </span>

      <div className="user_documents d-flex flex-directio-row gap-4">
        {data.images.map((item, index) => (
          <span
            className="d-flex flex-direction-row justify-content-between mb-3 gap-3"
            key={index}
          >
            <a href={item.ImageLink} target="_blank" rel="noreferrer">
              <img
                src="/Image_placeholder.svg"
                alt="download_button"
                style={{ cursor: "pointer", width: "5em" }}
                // onClick={() => downloadFile(index)}
              />
            </a>
          </span>
        ))}
        {(!data.images || data.images.length === 0) && <p>No Images present</p>}
      </div>
    </div>
  );
};

export default SocialDetail;
