import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Collapsible = ({ open, children, title }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="card collapseList">
        <div>
          <div
            className="p-3 border-bottom d-flex justify-content-between"
            style={{
              height: "3em",
              backgroundColor: isOpen ? "#CCEAFF" : "white",
              borderRadius: "5px",
            }}
          >
            <p className="font-weight-bold m-0" style={{ color: "#00365C" }}>
              {title}
            </p>
            <p
              style={{ backgroundColor: "transparent", cursor: "pointer" }}
              onClick={handleFilterOpening}
            >
              {!isOpen ? <FaChevronDown /> : <FaChevronUp />}
            </p>
          </div>
        </div>

        <div className="border-bottom">
          <div>{isOpen && <div className="">{children}</div>}</div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;
