import React from "react";

const VenueRulePage = ({ data }) => {
  return (
    <div className="user_detail_info venue-rules d-flex overflow-auto gap-4">
      <div className="mb-3">
        <div className="venue-rules-section">
          <p className="mb-2">
            <b>Rules</b>
          </p>
          <div className="venue-rules-grid" style={{ gap: "1rem", display: "flex", flexDirection: "column" }}>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule1"
                checked={data[0]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule1">Allows Returns</label>
            </div>

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule2"
                checked={data[1]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule2">Free Shipping</label>
            </div>

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule3"
                checked={data[2]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule3">Express Delivery</label>
            </div>

            <div className="d-flex justify-content-start align-items-center gap-2">
              <input
                type="checkbox"
                id="rule4"
                checked={data[3]?.RuleValue === 1}
                className="form-check-input"
                readOnly
              />
              <label htmlFor="rule4">Cash on Delivery</label>
            </div>
          </div>
        </div>

        <div className="venue-rules-section mt-4">
          <p className="mb-2">
            <b>Other Rules</b>
          </p>
          {data[4]?.other_rules ? (
            <p>{data[4].other_rules}</p>
          ) : (
            <p>N/A</p>
          )}
        </div> 
      </div>
    </div>
  );
};

export default VenueRulePage;
